import "./App.css";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
} from "react-share";

const quote = "Hello. This guy is awesome";
const url = "https://www.stphnsn.com";

const iconSize = "32";

const clickHandler = () => {
  console.log("Share button is clicked");
  console.log("Log to dataLayer");
};

const shareWindowCloseHandler = () => {
  console.log("Share window is closed");
  console.log("Log to dataLayer");
};

function App() {
  return (
    <div className="container">
      <div className="buttons">
        <div className="button">
          <FacebookShareButton
            url={url}
            quote={quote}
            onClick={clickHandler}
            onShareWindowClose={shareWindowCloseHandler}
          >
            <FacebookIcon round size={iconSize} />
          </FacebookShareButton>
        </div>

        <div className="button">
          <TwitterShareButton url={url} title={quote} via="rstphnsn">
            <TwitterIcon round size={iconSize} />
          </TwitterShareButton>
        </div>

        <div className="button">
          <WhatsappShareButton url={url} title={quote}>
            <WhatsappIcon round size={iconSize} />
          </WhatsappShareButton>
        </div>

        <div className="button">
          <FacebookMessengerShareButton appId="593243400724004" url={url}>
            <FacebookMessengerIcon round size={iconSize} />
          </FacebookMessengerShareButton>
        </div>
      </div>
    </div>
  );
}

export default App;
